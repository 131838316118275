:root{
    --gray-titles:#6A6A6A;
    --gray-text:#A8A8A8;    
    --gray-soft:#f4f4f4;    
    --blue:#A0A4E1;
    --purple:#7E42B8;
    --red:#b84248;
    --purple-strong:hsl(272, 33%, 23%);
    --green:rgb(16, 149, 16);

    --space:calc(.2rem)
}

h1{
    font-size: 1.195 !important;
}
h2{
    font-size: 1.095rem !important;
}
h3{
    font-size: .975rem !important;
}
h4{
    font-size: .875rem !important;
}
h5{
    font-size: .775rem !important;
}
h6{
    font-size: .675rem !important;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Montserrat SemiBold';
    margin-bottom: 0;
}
p,a{
    font-size: calc(.575rem + (.5vh+.5vw)/2) !important;
    margin-bottom: 0;
}

.bg-gray{
    background-color: var(--gray-text);
}
.bg-gray-soft{
    background-color: var(--gray-soft);
}
.bg-purple-strong{
    background-color: var(--purple-strong);    
}

.text-purple{
    color: var(--purple);
}
.text-blue{
    color: var(--blue);
}
.text-danger{
    color: var(--red);
}
.text-gray{
    color: var(--gray-text)
}
.text-gray-strong{
    color: var(--gray-titles)
}

.az-form-container{        
    border: 1px solid #80808063;
    border-radius: 1rem;
}
.az-card-container{
    background-color: white;
    padding: var(--space);    
}
.az-card-container--no-padding{
    background-color: white;
    padding: 0;    
}
.az-card-container--extra-padding{
    padding: 1rem;
}
.divider{
    height: 2px;
}

.d-inline-block-middle{
    vertical-align: middle;
    display: inline-block;
}

.az-list{
    position: relative;
    width: 100%;
    min-height: 500px;
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: hidden;
}
.az-list--full{
    min-height:100px;
    max-height:none;
}
.az-list--inner{
    min-height: none;
    max-height: none;
}
.az-list__loading{
    position: absolute;
    background-color:#FFFFFF99;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;   
    z-index: 1; 
}
.az-list--inner .az-list__content{
    max-height: none;
    overflow-y: hidden;
}
.az-list__content{
    position: relative; 
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;    
}
.az-list--full .az-list__content{
    max-height: none;
}
.az-list__content__item{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: .4rem;
    align-items:baseline;
    border-bottom: 2px solid var(--gray-soft);
    padding-left: var(--space);
    padding-right: var(--space);
    padding-top: .2rem;
    padding-bottom: .2rem;
}
.az-list__content__item__info{
    flex: 1 1 auto;
}
.az-list__content__item--bloqued-user{
    padding-left: calc(var(--space) / 2);
    padding-right: calc(var(--space) / 2);
    padding-top: .8rem;
    padding-bottom: .8rem;
}

.az-list__content__item--guru-request{
    flex-wrap: nowrap;
}

.az-list__content__item--conversations{
    cursor: pointer;
}
.az-list__content__item--seeker-request{
    padding-top: var(--space);   
    padding-bottom: 0;
}
.az-list__content__item--payment-method{    
    min-height: 60px;    
    justify-content:'center';
    border:1px solid gainsboro;    
    padding: 0 10px;
    cursor: pointer;
}

.az-list__content__item--payment-method-add{
    min-height: 50px;
}
.az-list__content__item--payment-method-add--error{
    border:2px solid var(--red);
}
.payment-method__card img{    
    height: 20px;
    width: 30px;    
}

.az-horizontal-list{
    overflow-x: auto;
    width: 100%;
    position: relative;
    padding: .4rem;
}
    .az-horizontal-list__content{
        white-space: nowrap;
    }
        .az-horizontal-list__content__item{
            display: inline-block;
            padding: .4rem;
            text-align: center;
            cursor: pointer;
        }
        .az-horizontal-list__content__item--seeker-conversation{

        }
.row-hover{
    z-index: 99;
    margin-top: -.2rem;
}
.cursor-pointer{
    cursor: pointer;
}