.text-line{
    position: relative;
    /* display: flex; */
    text-align: center;    
}
    .text-line__line{
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: var(--gray-text);
        top: calc(50% - 1px);
        z-index: 0;
    }
    .text-line__text{
        display: inline-block;
        text-align: center;
        z-index: 1;
        padding: .5rem;
        margin-left: auto;
        background-color: white;
        margin-right: auto;
        position: relative;
    }
.circled-label{  
    position: relative;
    width: 28px;
    height: 28px;
    padding: .3rem;  
    border-radius: 50%;    
}
.circled-label__content{
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.circled-label__content > *{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}