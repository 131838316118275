.az-tutorial{
    padding: .4rem;
    min-width: 350px;
    width: 100%;
}
    .az-tutorial__header{
        width: 100%;   
        display: flex;     
        padding-top: 1rem;
        padding-bottom: 1rem;
        justify-content: center;
        background-color: white;
    }
        .az-tutorial__header__img{
            width: 128px;
            height: 128px;            
        }
        .az-tutorial__header__img img{
            width: 100%;  
            height: 100%;       
        }

    .az-tutorial__buttons{
        position: relative;
    }
    .az-tutorial__buttons__button{
        position: absolute;
        top: -1.4rem;
        cursor: pointer;
    } 
    
    .az-tutorial__buttons__button--left{
        left: .4rem;
    }
    .az-tutorial__buttons__button--right{
        right: .4rem;
    }

    .az-tutorial__content{
        padding: 1rem;
        padding-top: 1.5rem;
        background-color: var(--gray-soft);
        min-height: 100px;
    }

.az-dots{
    display: flex;
    flex-direction: row;    
}
.az-dots__dot{
    width: .6rem;
    height: .6rem;
    border-radius: .3rem;
    margin: .2rem;
    background-color: var(--gray-text);
    cursor: pointer;
}
.az-dots__dot--active{
    background-color: var(--purple);
}
