@keyframes holderNotificationAnimation {
    from {background-color: var(--blue);}
    to {background-color: var(--gray-text);}
}
.conversations-view-wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;    
    background-color: transparent;
    justify-content: end;
    top: 0;
    left: 0;
    z-index: 9995;    
    pointer-events:none;    
}

.conversations-view{
    background-color: transparent;
    align-self:flex-end;     
    pointer-events:all;
    margin-right: 1rem;
}


.conversation-holder{
    width: 300px;
    background-color: white;
    background-clip: padding-box;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem; 
    --webkit-border-top-left-radius: 1rem;
    --webkit-border-top-right-radius: 1rem;     
    --ms-border-top-left-radius: 1rem;
    --ms-border-top-right-radius: 1rem;  
    box-shadow: 0 0 1px 0 var(--gray-text);       
    overflow: hidden;
    border: 0.7px solid var(--gray-text);
}
.conversation-holder--chat{
    width: 400px;
}

.conversation-holder__header{
    background-color: var(--purple-strong);
    padding: .5rem;
    pointer-events: all;    
}
.conversation-holder__header--animate{
    animation-name:holderNotificationAnimation;
    animation-duration:.5s;    
    animation-iteration-count:1;
}
.conversation-holder--chat .conversation-holder__header{
    background-color: white;
    border-bottom: 1px solid var(--gray-text);

}
.conversation-holder__content{    
    /* padding:0 var(--space); */
    height: 0;
    width: 100%;
    overflow-y: auto;
}
.conversation-holder--chat  .conversation-holder__content{
    overflow-y: hidden;
}
.conversation-holder--show .conversation-holder__content{
    /* padding: var(--space); */
    min-height: calc(300px + 20vh);
}

/*CHAT IMAGE*/
.chat-image{
    position: relative;
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    padding: .4rem;
    flex: 0 0 auto;
}.chat-image--md{
    width: 100px;
    height: 100px;
}
.chat-image--lg{
    width: 200px;
    height: 200px;
}
.chat-image--bordered .chat-image__image{
    border: 4px solid var(--gray-soft);
}
.chat-image--bordered__0 .chat-image__image{
    border-color: var(--purple);
}
.chat-image--bordered__1 .chat-image__image{
    border-color: var(--gray-soft);
}

.chat-image__read-status{
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
}
    .chat-image__read-status__0{
        position: relative;
        width: 10px;
        height: 10px;
        margin:auto auto;
        margin-top: .5rem;
        background-color: var(--purple);
        border-radius: 50%;
    }
    .chat-image__read-status__1{
        position: relative;
        width: 10px;
        height: 10px; 
        margin:auto auto;      
        margin-top: .5rem; 
        background-color: var(--gray-soft);
        border-radius: 50%;
    }
    .chat-image__read-status__2{
        position: relative;        
        width: 100%;
        height: 100%;        
    }
    .chat-image__read-status__2 > *{        
        margin:auto auto;
        color: var(--gray-titles);
        font-size: 10px;
    }



.chat-image__image{
    border-radius: 50%;
    width: 100%;
    overflow: hidden;    
    display: flex;
}.chat-image__image img{
    width: 100%;
}
.chat-image__label{
    width: 100%;
    text-align: center;
}
.chat-image__image__loading{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}
.chat-image__image__loading > *{
    margin: 0;
}
