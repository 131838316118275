.az-chat{
    height: 100%;
    width: 100%;        
    position: relative;
    overflow-y: hidden;

}
    .az-chat__messages-container{        
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 148px);   
        pointer-events: inherit;     
    }
        .az-chat__messages{      
            padding: .4rem;
        }

    .az-chat__tools{  
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;           
        width: 100%;
        height: 64px;   
        border-top: .5px solid var(--gray-text);           
    }

    .az-chat__header{     
        width: 100%;   
        height: 80px;   
        border-bottom: .5px solid var(--gray-text);   
        padding:.4rem;
        margin: 0;
    }

.az-chat-message{
    width: 100%;
    margin-top: .4rem;
    margin-bottom: .4rem;
}
    .az-chat-message__bubble{
        position: relative;
        padding: .4rem;
        max-width: 80%;
        margin-right: auto;
        margin-left: 0;
        background-color:var(--blue);
        color: white;
        border-radius: var(--space);
        word-wrap: break-word;
        white-space: normal;
        text-align: left;
    }
    .az-chat-message__bubble--right{
        margin-left: auto;
        margin-right: 0;
        background-color:var(--gray-soft);
        color: var(--gray-titles);
        text-align: start;
    }

    .az-chat-message__bubble__text{
        
    }
    .az-chat-message__bubble__img{
        position: relative;
        width: 100%;
    }
        .az-chat-message__bubble__img > *{            
            width: 100%;
        }
    .az-chat-message__bubble__audio{
        width: 100%;
        padding: .4rem;
    }
    .az-chat-message__bubble__map{
        width: 100%;        
        margin: auto;
    }
