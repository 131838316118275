@font-face{
    font-family: "Montserrat Light";
    src: local('Montserrat Light'),url('../../fonts/montserrat-light.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}@font-face{
    font-family: "Montserrat Medium";
    src: local('Montserrat Medium'),url('../../fonts/montserrat-medium.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}@font-face{
    font-family: "Montserrat SemiBold";
    src: local('Montserrat SemiBold'),url('../../fonts/montserrat-semibold.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

html, body {
    font-family: "Montserrat Light", "Montserrat Medium", "Montserrat SemiBold", "Open Sans", "Arial", "Helvetica", sans-serif;
}
.logo{
	margin: 0px;
	padding: 0px;
	width:100%;
}
.logoHide{
	display: none;
}
.container2{
	width: 100%;
	background-color: #222222;
}
.container{
	max-width: 100%;
	margin: 0 auto;
	background-color: white;
}
.bgmenuHide{
	font-family: "Montserrat Light", "Open-Sans", sans-serif;
}
.navigationHide{
	position: absolute;
    right: 0px;
     padding-bottom:1rem;
}

.smart-scroll{
  position: fixed;
  top: 0;
  z-index: 1030;
}
.scrolled-down{
   transform:translateY(-100%); transition: all 0.3s ease-in-out;
}
.scrolled-up{
   transform:translateY(0); transition: all 0.3s ease-in-out;

}

.navbar{
	padding: 0px;
}
.navbar-brand{
	padding-top: 0px;
}
.navigationHide{
	top: 17px;
	padding-right:2rem;
}
.navbar-dark .navbar-nav .nav-link {
  color: #000;
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #8040B8;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #8040B8;
}
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active{
	color: #8040B8;
}

.navbar-custom.top-nav-collapse {
    padding: 0.5rem 1.5rem 0.5rem 2rem;
	box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
	background-color: #36186b;
	/*opacity: 98%;*/
}

.navbar-custom.top-nav-collapse .navbar-brand.logo-text {
	color: #fff;
}

.navbar-custom.top-nav-collapse .nav-item .nav-link {
	color: #fff;
}

.navbar-custom.top-nav-collapse .nav-item .nav-link:hover,
.navbar-custom.top-nav-collapse .nav-item .nav-link.active {
	color: #ae8dcc;
}

.sectionInicio{
	padding-top: 5rem;
	margin-bottom: 5rem;
}
.columnaIzq{
	padding-top: 10%;
}
.titleInicio{
	font-size: 70px;
	font-family: "Montserrat Medium", "Open-Sans", sans-serif;
	line-height: 1;
	padding-top: 30px;
	padding-bottom: 30px;
	width: 80%;
	margin: auto;
}
.subtitleInicio{
	font-size: 22px;
	font-family: "Montserrat Light", "Open-Sans Light", sans-serif;
	line-height: 1.7;
	width: 80%;
	margin: auto;
	padding-bottom: 10px;
}
.world{
	padding-top: 5%;
	padding-right: 6%;
	width: 80%;
    
}
.appstore:hover{
	filter: invert(30%) sepia(100%) saturate(500%) hue-rotate(230deg) brightness(90%) contrast(77%);
}
.googleplay:hover{
	filter: invert(30%) sepia(100%) saturate(500%) hue-rotate(230deg) brightness(90%) contrast(77%);
}

.separador1{
	height: 5px;
    width: 50%;
    float: right;
    background-color: #8040B8;
    margin-top: 10%;
    margin-right: -15px;
}
.separador2{
	height: 5px;
    width: 50%;
    float: left;
    background-color: #8040B8;
    margin-left: -15px;
}
.infoFunciona{
	padding-bottom: 5%;
}
.titleFunciona{
	font-size: 50px;
	font-family: "Montserrat Medium", "Open-Sans", sans-serif;
	margin-top: 5%;
	padding-top: 5%;
}
.parrafo1{
	font-size: 22px;
	font-family: "Montserrat Light", "Open-Sans Light", sans-serif;
	margin-top: 3%;
}
.sectionTabs{
	/*padding-top: 10%;*/
}
.sectionGuru, .sectionSeeker{
	background-color: #8040B8;
	height: auto;
	/*margin-top: 0.4rem;*/
}
.columna1, .columna2, .columna3{
	height: auto;
}
a{
    color: #000;
}
a:hover{
	color: #000;
	text-decoration: none;
}
.nav-t { width: 100% auto; list-style: none; margin: 0px; padding: 0px; }
.nav-t > li > a { display: block; background-color: #CEB5E3; border:0px;}
.nav-tabs { *zoom: 1; border:0px;}
.nav-tabs:before, .nav-tabs:after { display: table; content: "";  }
.nav-tabs:after { clear: both;  }
.nav-tabs > li { float: left; margin: 0px; padding: 0px; border: 0px solid #E3E4F4;}
.nav-tabs > li > a .guru{ border:0px;  }
.nav-tabs > li > a:hover, .nav-tabs > li > a:focus { background-color: #B68CD9;   }
.nav-tabs > .active > a, .nav-tabs > .active > a:hover, .nav-tabs > .active > a:focus { background-color: #8040B8; color:white; border: 0px;}
.tab-content.active { display: block; border:0px;}
.tab-content.hide { display: none; border:0px;}

.bg-guru2{
	padding-right: 0.2rem !important;
}
.bg-seeker2{
	padding-left: 0.2rem !important;
}
.bg-tab1:hover {
	/*background-color: #B68CD9;*/
	background-color: #683791;
	color: white;
}
.bg-tab2:hover {
	/*background-color: #B68CD9;*/
	background-color: #683791;
	color: white;
}
.nav-tabs > .active > a  .guruWhite{
	display: block;
}
.nav-tabs > .active > a  .guru{
	display: none;
}
.nav-tabs > .active > a  .seekerWhite{
	display: block
}
.nav-tabs > .active > a  .seeker{
	display: none;
}

.guruWhite{
	display: none;
	margin: 0 auto;
}
.seekerWhite{
	display: none;
	margin: 0 auto;
}

.card{
	background-color: transparent;
	justify-content: center;
	text-align: center;
	border:0px;

}
.card-body{
	padding-top: 0px;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-size: 14px;
	font-family: "Montserrat Light", "Open-Sans Light", sans-serif;
	justify-content: center;
}
.my-card-title{
	font-size: 16px;
	font-family: "Montserrat Medium", "Open-Sans", sans-serif;
	color: white;
}


.cellphone{
	position: absolute;
	width: 50%;
	height: auto;
	top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
	transition: width 0.5s, height 1s;
}

.columna2:hover > img {
	width: 68%;
}
.ganancias, .flexibilidad, .aprendizaje, .elige, .tratodirecto, .reputacion, .solucionesrapidas, .contactoseguro{
	width: 20%;
	height: auto;
	margin: 0 auto;
}
.sectionSteps{
	background-color: #F7F8FB;
	padding-top: 8rem;
	padding-bottom: 8rem;
}
.titleSteps{
	font-size: 50px;
	font-family: "Montserrat Medium", "Open-Sans", sans-serif;
	/*margin-top: 5%;
	margin-bottom: 5%;
	padding-top: 5%;
	padding-bottom: 5%;
	*/
	text-align: center;
}
.stepsSeekers[data-v-32f968e2], .stepsGurus[data-v-32f968e2]{
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-around;
	justify-content: space-around;
	position: relative;
	/*height: 195px;*/
	padding-right: 10%;
	padding-left: 10%;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.step[data-v-32f968e2]{
	margin: 0px 40px 18px;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	color:#fff;
	z-index: 9;
	position: relative;
}
.step span[data-v-32f968e2]{
	width: 100%;
	height: 100%;
	background-color: #000;
	border-radius: 20px;
	position: absolute;
	font-size: 20px;
	font-family: "Montserrat SemiBold", "Open-Sans", sans-serif;
	left: 0;
	top: 0;
}
.desc[data-v-32f968e2]{
	text-align: center;
	-webkit-box-flex:0;
	-webkit-flex:0 1 120px;
	flex: 0 1 120px;
	font-size: 18px;
	font-family: "Montserrat Light", "Open-Sans Light", sans-serif;
	line-height: 1.2;
	padding: 0 20px;
	-webkit-box-sizing:border box;
	position: absolute;
	color: #3e434e;
	top:55px;
	left: 50%;
	width: 200px;
	-webkit-transform:translateX(-50%);
	transform: translateX(-50%);
}
.arrow[data-v-32f968e2]{
	position: relative;
	-webkit-box-flex:1;
	-webkit-flex:1;
	flex: 1;
	z-index: 9;
}
.arrow[data-v-32f968e2]:before{
	content: "";
	width: 100%;
	height: 2px;
	position: absolute;
	top: 20px;
	background-color: #000;
	z-index: -1;
}
.btnDownload{
	margin: 3%;
}
.btnDownload:hover {
	opacity: 80%;
}
.imgCard{
	width: 80px;
	height:60px;
	margin: 0 auto;
}
.my-card-titleNivel{
	font-size: 30px;
	font-family: "Montserrat Medium", "Open-Sans", sans-serif;
	color: #000;
	padding-bottom: 0.5rem;
}
.my-card{
	background-color: white;
	border-color:#DFDFDF solid 2px;
	box-shadow: 0px 2px 8px #DFDFDF;
	padding-top: 4rem;	
}
.title{
	font-size: 50px;
	font-family: "Montserrat Medium", "Open-Sans" sans-serif;
	margin-top: 5%;
}

.bgslide{
	background-color: #E3E4F4;
}
.carousel-indicators li{
	background-color: #CEB5E3;
	width: 10px;
	height: 10px;
	border-radius: 100%;
}
.carousel-indicators .active{
  	background-color: #8040B8;
}
.carousel-indicators li::before {
	  position: absolute;
	  top: -10px;
	  left: 0;
	  display: inline-block;
	  width: 100%;
	  height: 10px;
	  content: "";
}

.carousel-indicators li::after {
	  position: absolute;
	  bottom: -10px;
	  left: 0;
	  display: inline-block;
	  width: 100%;
	  height: 10px;
	  content: "";
}
.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%238040B8' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%238040B8' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.slide1{
    width: 70%;
}
.infoSecurity{
	background-color: #CEB5E3;
	height: 15rem;
	font-family: "Montserrat SemiBold", "Open-Sans", sans-serif;
	font-size:35px;
	color: white;
}

.security1{
    text-align: center;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);	
}
.infoSecurity:hover > .infohide1 {
	background-color: #E3E4F4;
	display: block;
	position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.infoSecurity:hover{
	background-color: #E3E4F4;
}
.infohide1{
	display: none;
	width: 100%;
	/*height: 15rem; */
	/*padding-top: 20%; */
}
.infoSecurity:hover > .security1{
	display: none;
}
.titleSecurity{
	font-family: "Montserrat SemiBold", "Open-Sans", sans-serif;
	font-size:26px;
	color: #202124;
	text-decoration:underline;
}
.subtitleSecurity{
	font-family: "Montserrat Medium", "Open-Sans", sans-serif;
	font-size: 16px;
	color:#202124;
}
.sectionContact{
	background-color: #CEB5E3;
	
}

.bgfooter {
  background-color: #000;
  height:auto;
}
.terms{
	font-family: "Montserrat Light", "Open-Sans Light", sans-serif;
	color: white;
	font-size: 14px;
}
.termuse, .privacy{
	color: white;
}
.termuse:hover{
	color: #CEB5E3;
}
.privacy:hover{
	color: #CEB5E3;
}
.icons{
	text-align: right;
}

.titleDatos{
	font-family: "Montserrat Light", "Open-Sans Light", sans-serif;
	font-size:22px;
	color: #202124;
}
/*Flecha para hacer la pagina hacia arriba*/
.ir-arriba{
  display:none;
  background-repeat:no-repeat;
  font-size:20px;
  color:black;
  cursor:pointer;
  position:fixed;
  bottom:85px;
  right:2px;
  z-index:15;
}
.ir-arriba:hover{
	opacity: 80%;
}
.iconF:hover{
	filter: invert(30%) sepia(100%) saturate(500%) hue-rotate(230deg) brightness(70%) contrast(27%);
}
.iconT:hover{
	filter: invert(30%) sepia(100%) saturate(500%) hue-rotate(230deg) brightness(70%) contrast(27%);
}


@media (max-width: 991px) {
	.stepsSeekers[data-v-32f968e2], .stepsGurus[data-v-32f968e2]{
		-webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    flex-direction: column;
	    -webkit-box-pack: end;
	    /*height: 600px;*/
	}
	.step[data-v-32f968e2]{
		margin: 0px;
		width: 100%;
		height: auto;
	}
	.step span[data-v-32f968e2]{
		position: relative;
	    display: inline-block;
	    width: 40px;
	    height: 40px;
	}
	.desc[data-v-32f968e2]{
		position: relative;
	    top: 8px;
	    padding-bottom:20px;
	    margin-bottom:20px;
	    width: auto;
	}
	.arrow[data-v-32f968e2]{
		display: none;
	}
	.slide1{
		width: 70%;
	}
	.slidec{
		width: 60%;
	}
	.titleSecurity{
		font-family: "Montserrat SemiBold", sans-serif;
		color: #202124;
		text-decoration:underline;
	}
	.subtitleSecurity{
		font-family: "Montserrat Medium", sans-serif;
		font-size: 16px;
		color:#202124;
	}
	
	.nav-link{
		text-align: center;
	}
	
	.navigationHide{
	    right: 0px;
	    padding-right: 1rem;
	}
    .navbar-nav{
    	margin-top: 3rem;
    }

	.navbar-dark .navbar-toggler-icon {
	  	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(206, 181, 227, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
	}
	.navbar-dark .navbar-toggler {
		margin-right: 1rem;
	  	color: rgba(255, 255, 255, 0.5);
	  	border-color: rgba(206, 181, 227, 0.8);
	}
	.navbar-collapse{
		background-color: #36186b;
		top:0px;
		width: 100%;
		overflow-x: hidden;
  		transition: 0.3s;
	}
	.navbar-dark .navbar-nav .nav-link{
		color: white;
	}
	.navbar-custom.top-nav-collapse{
		padding: 0.5rem 0.5rem 0.5rem 2rem;
	}
	.navbar-dark .navbar-nav .show > .nav-link,
	.navbar-dark .navbar-nav .active > .nav-link,
	.navbar-dark .navbar-nav .nav-link.show,
	.navbar-dark .navbar-nav .nav-link.active {
	  color: #CEB5E3;
	}
	.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
	  color: #CEB5E3;
	}
	.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active{
		color: #CEB5E3;
	}
	.logo{
		margin: 0px;
		padding: 0px;
		width:75%;
	}
	.close {
		font-size: 2rem;
		float: right;
		color: #fff;
		line-height: 1;
		text-shadow: none;
		opacity: 10;
		font-weight: 200;
	}
	button.close{
		padding:0.3rem 0.5rem 0.5rem 0.5rem;

		background-color: transparent;
		border:0px;
	}
	.close:hover, .close:focus{
		color: #bdbdbd;
		text-decoration: none;
		opacity: .75;
	}
	.titleFunciona{
		padding-top: 3rem;
	}
}

@media(max-width: 768px){
	.infoSecurity{
		background-color: #CEB5E3;
		height: 15rem;
		font-family: "Montserrat SemiBold", sans-serif;
		font-size:30px;
		color: white;
	}
	.infohide1{
		display: none;
		width: 100%;
		/*height: 15rem; */
		/*padding-top: 12%; */
	}
	.logo{
		width: 90%;
		height: auto;
	}
	.navbar-dark .navbar-toggler{
		position: absolute;
		float: right;
		right: 0;
	}
	.sectionInicio{
		padding-top: 7rem;
	}
	.desc[data-v-32f968e2]{
	    font-size: 16px;
	}
	.titleFunciona{
		padding-top: 3rem;
	}
	.infoFunciona{
		padding-bottom: 3rem;
	}
	.titleInicio{
		width: 100%;
		margin: auto;
		font-size: 50px;
	}
	.title{
		font-size: 50px;
	}
	.titleSteps{
		font-size: 50px;
	}


	.sectionSteps{
		padding-top: 3rem;
		padding-bottom: 3rem;
	}


}

@media (max-width: 575px) and (min-width: 426px) {
	.nav-link{
		text-align:center;
	}
	.navbar-dark .navbar-toggler{
		position: absolute;
		float: right;
		right: 0;
	}
	.logo{
		width: 60%;
		height: auto;
	}
}

@media (max-width: 767px) and (min-width: 320px) {
	.icons{
		text-align: center;
	}
	.terms{
		text-align: center;
	}
	.ir-arriba{
	  display:none;
	  background-repeat:no-repeat;
	  font-size:20px;
	  color:black;
	  cursor:pointer;
	  position:fixed;
	  bottom:150px;
	  right:10px;
	  z-index:15;
	}
	.infoSecurity{
		background-color: #CEB5E3;
		height: 15rem;
		font-family: "Montserrat SemiBold", sans-serif;
		font-size:30px;
		color: white;
	}
	.infohide1{
		display: none;
		width: 100%;
		/*height: 15rem; */
		/*padding-top: 9%; */
	}
	.titleSecurity{
		font-size:22px;
	}
	.subtitleSecurity{
		font-size: 14px;
	}
}

@media (max-width: 425px) {

	.infoFunciona{
		padding-bottom: 3rem;
	}
	.parrafo1{
		font-size: 16px;
		text-align: justify;
	}
	.infoSecurity{
		height: 15rem;
		font-size:24px;
	}
	.infohide1{
		/*height: 15rem; */
		/*padding-top: 15%; */
	}
	.titleSecurity{
		font-size:25px;
	}
	.subtitleSecurity{
		font-size: 15px;
	}
	.separador1{
   	    margin-top: 20%;
    	height: 3px;
	}
	.separador2{
		height: 3px;
	}
	.nav-link{
		text-align:center;
	}

	.logo{
		width: 70%;
		height: auto;
	}
	.navbar-dark .navbar-toggler{
		position: absolute;
		float: right;
		right: 0;
	}
	.sectionInicio{
		padding-top: 10rem;
		padding-bottom: 1rem;
	}
	.columnaIzq{
		padding-top: 0px;
	}
	.titleInicio{
		width: 100%;
		margin: auto;
		font-size: 45px;
	}
	.my-card-title{
		font-size: 14px;
	}
	.my-card-subtitle2{
		font-size: 12px;
	}
	.my-card-titleNivel{
		font-size: 18px;
	}
	.subtitleInicio{
		width: 100%;
		margin: auto;
	}
	.terms{
		text-align: center;
	}
	.icons{
		text-align: center;
	}
	.ir-arriba{
	  display:none;
	  background-repeat:no-repeat;
	  font-size:20px;
	  color:black;
	  cursor:pointer;
	  position:fixed;
	  bottom:130px;
	  right:10px;
	  z-index:15;
	}
	.desc[data-v-32f968e2]{
	    font-size: 18px;
	}



	.titleInicio{
		font-size: 32px;
	}
	.title{
		font-size: 32px;
	}
	.titleSteps{
		font-size: 32px;
	}
	.titleFunciona{
		padding-top: 3rem;
		font-size:32px;
	}


}

@media(max-width: 375px){
	.stepsSeekers[data-v-32f968e2]{
	    /*height: 640px;  */
	}
	.stepsGurus[data-v-32f968e2]{
	    /*height: 760px;  */
	}
	.titleFunciona{
		padding-top: 4rem;
	}
	.navbar-brand{
		display: inline-block;
		padding-bottom: 0.3125rem;
        margin-right: 1rem;
		font-size: 1.25rem;
		line-height: inherit;
		white-space: nowrap;
		width: 78%;
	}
	.logo{
		width: 80%;
		height: auto;
	}
	.my-card{
		padding-top: 0.5rem;
	}
	.separador2{
		margin-bottom: 10%;
	}
	.sectionInicio{
		padding-top: 8rem;
		padding-bottom: 1rem;
	}
	.titleInicio{
		font-size: 40px;
		width: 100%;
		margin: auto;
	}

}

@media(max-width: 320px){
	.navbar-brand{
		display: inline-block;
		padding-bottom: 0.3125rem;
        margin-right: 1rem;
		font-size: 1.25rem;
		line-height: inherit;
		white-space: nowrap;
		width: 70%;
	}
	.logo{
		width: 90%;
		height: auto;
	}
	.titleInicio{
		font-size: 35px;
		width: 100%;
		margin: auto;
	}
	
	.ir-arriba{
	  display:none;
	  background-repeat:no-repeat;
	  font-size:20px;
	  color:black;
	  cursor:pointer;
	  position:fixed;
	  bottom:150px;
	  right:10px;
	  z-index:15;
	}
	.sectionInicio{
		padding-top: 7rem;
		padding-bottom: 1rem;
	}
}

@media (min-width: 320px) {
	.title{
		font-family: "Montserrat Medium", sans-serif;
		margin-top: 0%;
	}
	.slidec{
		width: 73%;
	}
	.iconDatos{
		width: 70%;
	}
	
}  

@media (min-width: 425px) {
	.separador1{
    	margin-top: 10%;
	}
	.iconDatos{
		width: 70%;
	}
	.my-card{
		height: 290px;	
		padding-top: 1.5rem;
	}
}

@media (min-width: 768px) {
	.my-card{
		background-color: white;
		border-color:#DFDFDF solid 2px;
		box-shadow: 0px 2px 8px #DFDFDF;
		height: 300px;	
		padding-top: 3rem;
	}
	.slidec{
		width: 68%;
	}
	.separador1{
    	margin-top: 15%;
	}
	.separador2{
    	margin-top: 8%;
	}
	.iconDatos{
		width: 70%;
	}
	.ir-arriba{
	   display:none;
	   background-repeat:no-repeat;
	   font-size:20px;
	   color:black;
	   cursor:pointer;
	   position:fixed;
	   bottom:100px;
	   right:10px;
	   z-index:15;
	}
	
}

@media (min-width: 991px) {
	.iconDatos{
		width: 80%;
		height: auto;
	}
	.nav-link{
		margin: 0rem 0.5rem;
	    padding: 0rem 1rem;
	}
}

@media (min-width: 1200px) {
	.colPrincipiante{
		padding-right: 3%; 
	}
	.colAprendiz{
		padding-left: 3%;
	}
	.colProfesional{
		padding-right: 3%; 
	}
	.colSamaritano{
		padding-left: 3%;
	}
}


@media (min-width: 1440px) {
	.container{
		/*max-width: 1440px;*/
		margin: 0 auto;
		background-color: white;
	}
	.sectionTabs{
		/*padding-top: 15%;*/
	}
	.iconDatos{
		width: 90%;
		margin-bottom:0.3rem;
	}
	.titleDatos{
		font-family: "Montserrat Light", sans-serif;
		font-size:30px;
		color: #202124;
	}
	.titleInicio{
		width: 70%;
		margin: auto;
	}
	.subtitleInicio{
		width: 70%;
		margin: auto;
	}
	.titleFunciona{
		font-size: 70px;
	}
	.world{
		margin-top: 8%;
		padding-right: 8%;
		width: 90%;
    }
    .navigationHide{
		top: 10px;
		padding-right:1rem;
	}
	.nav-link{
		margin: 0rem 0.5rem;
	    padding: 0rem 1rem;
	}
	.bgmenuHide{
		font-size: 1.2rem;
	}
    .ir-arriba{
	  display:none;
	  background-repeat:no-repeat;
	  font-size:20px;
	  color:black;
	  cursor:pointer;
	  position:fixed;
	  bottom:95px;
	  right:60px;
	  z-index:15;
	}
	.parrafo1{
		font-size: 30px;
	}
	.my-card{
		padding-top: 2rem;
	}
	.my-card-title{
		font-size: 24px;
		font-family: "Montserrat Medium", sans-serif;
		color: white;
		padding-bottom: 0.5rem;
	}
	.card-body{
		padding-top: 0px;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		font-size: 21px;
	}
	.cellphone{
		width: 60%;
		height: auto;
	}
	.ganancias, .flexibilidad, .aprendizaje, .elige, .tratodirecto, .reputacion, .solucionesrapidas, .contactoseguro{
		width: 20%;
		height: auto;
		margin: 0 auto;
	}
	.titleSteps{
		font-size: 70px;
	}
	.title{
		font-size: 70px;	
	}
	.desc[data-v-32f968e2]{
		font-size: 22px;
	}
	.my-card-titleNivel{
		font-size: 26px;
	}
	.titleSecurity{
		font-size:22px;
	}
	.subtitleSecurity{
		font-size: 18px;
	}
	.infoSecurity{
		height: 16rem;
	}

}

@media (min-width: 1680px){
}

@media (min-width: 1920px){
}

@media (min-width: 2400px){
 	.container{
		width: 1440px;
		margin: 0 auto;
		background-color: white;
    }
	.navbar-custom.top-nav-collapse {
		width: 1440px;
		margin: 0 auto;
	}
	.fixed-top{
		width: 1440px;
		margin: 0 auto;
	}
	.nav-link {
		font-size: 1.5rem;
	}
	.iconDatos{
		width: 50px;
		height: auto;
		margin-bottom:1rem;
	}
    .parrafo1{
		font-size: 34px;
		font-family: "Montserrat Light", sans-serif;
		margin-top: 3%;
	}
	.my-card-title{
		font-size: 34px;
		font-family: "Montserrat Medium", sans-serif;
		color: white;
		padding-bottom: 0.5rem;
	}
	.card-body{
		padding-top: 0px;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		font-size: 22px;
	}
	.cellphone{
		width: 80%;
		height: auto;
	}
	.ganancias, .flexibilidad, .aprendizaje, .elige, .tratodirecto, .reputacion, .solucionesrapidas, .contactoseguro{
		width: 30%;
		height: auto;
		margin: 0 auto;
	}
	.my-card{
		height: 380px;
		padding-top: 2rem;
	}
	.my-card-titleNivel{
		font-size: 34px;
	}
	.titleSecurity{
		font-family: "Montserrat SemiBold", sans-serif;
		font-size:26px;
		color: #202124;
		text-decoration:underline;
	}
	.subtitleSecurity{
		font-family: "Montserrat Medium", sans-serif;
		font-size: 20px;
		color:#202124;
	}
}



