.az-modal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #000000BB;
    top: 0;
    left: 0;
    z-index: 9998;    
    cursor: pointer;
    overflow-y: scroll;
}
.az-modal--title{
  z-index: 9997;  
}
.az-modal--desicion{
  z-index: 9996;  
}
.az-modal--hide{
    display: none;
}
    .az-modal__body{
        position: relative;
        max-width: 600px;
        width: 100%;
        align-self: baseline;
        justify-self: center;
        border-radius: 1rem;
        border-color: none;
        background-color: white;
        cursor: default;
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
        .az-modal__body__footer{
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            background-color: var(--gray-soft);  
            padding: .8rem;
        }
        .az-modal__body__header{
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            background-color: var(--gray-soft);
            text-align: center;
            padding: .2rem;
        }
        .az-modal__body__header__title{
            color: var(--gray-titles);
            font-size: 1.175rem;
            padding: .5rem;
            font-family: 'Montserrat SemiBold';
        }
        .az-modal__body__content{
            padding: .5rem;
        }
        .az-modal__body__close{
            position: absolute;
            right: 0;
            top: -3rem;
            background-color: transparent;
            color: white;
            box-shadow: none;
            border: none;
            font-size: 2rem;
            color: var(--gray-text);
        }   
.az-loading{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #000000BB;
    top: 0;
    left: 0;
    z-index: 9999;    
}.az-loading--hide{
    display: none;
}
.az-loading__body{
    color: white;
    position: relative;
    max-width: 600px;
    width: 100%;
    align-self: center;
    justify-self: center;
    border-radius: 1rem;
    border-color: none;
    text-align: center;
    background-color: transparent;
}

.az-bar-menu{
    position: absolute;
    left: 0;
    right: 0;
    width: 380px; 
    padding-top: .4rem; 
    padding-bottom: .4rem; 
    background-color: white;  
    z-index: 9995;
    box-shadow: 1px 1px 6px grey;
    overflow: hidden;
}
.az-bar-menu--hide{
    display: none;
}
.az-bar-menu__header{
    padding: .4rem;
    padding-left: 1rem;    
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.az-bar-menu__body{
    padding: .4rem;
   padding-top: .6rem;
   padding-left: 1rem;
}
