/* ### AZSelect */
.az-select{
    position: relative;
    border-radius: 1rem;
    border-color: none;
    overflow: hidden;
    background-color: var(--purple);
    color: white;
    width: 100%;
    border: 1px solid var(--purple);
}
.az-select--blue{
    background-color: var(--blue);
    border: 1px solid var(--blue);    
}
.az-select--gray{
    background-color: transparent;
    border: 1px solid var(--gray-text);
    color: var(--gray-titles);
}
.az-select--disabled{
    pointer-events: none;
    opacity: .6;
}
.az-select--error{
    border-color: var(--red);
}
    .az-select__header{
        width: 100%;  
        display: flex;
        background-color: inherit;
        color: inherit;
        border: none;
        outline: none;
    }
        .az-select__header__title{
            color:inherit;
            font-size: .9rem;
            padding: .2rem;
            flex: 1 1 auto;   
            text-align: start;         
            text-overflow: ellipsis;
            overflow-x: hidden;            
        }
        .az-select__header__icon{
            color: inherit;            
            width: 10px;
            height: 10px;
            flex: 0 0 32px;
            text-align: center;
            align-self: center;
            display: table;        
        }
        .az-select__header__icon > *{
            font-size: 1rem;
            vertical-align: middle;
            text-align: center;
        }
    .az-select__list{
        position: relative;
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        background-color: white;
        color: var(--gray-titles);
    } 
        .az-select__list__content{
            width: 100%;
            padding: .2rem;
        } 
            .az-select__list__content__item,.select__list__content__blank{
                width: 100%;
                font-size: .9rem;
                font-family: "Montserrat Light";
                border: none;
                outline: none;
                background-color: transparent;
                color: inherit;
                text-align: start;
                padding: .2rem; 
                border-bottom: 1px solid gray;               
            }
            .az-select__list__content__item.text-purple{
                text-shadow: 1px 1px 1px var(--purple);
            }
            .az-select__list__content__blank{
                width: 100%;                
                margin: auto;
                height: 1px;
                background-color: var(--gray-titles);
                padding: 0;
                border-bottom: none;
            }
            .az-select__list__content__item--disabled{
                pointer-events: none;
                cursor:none;
                opacity: .6;                
                border-bottom: none;
                text-align: center!important;
                color: var(--gray-titles);
            }
            .az-select__list__content__item--suggestion{
                color: var(--purple) !important;
                text-align: center!important;
                text-decoration: underline;
                border-bottom: none;
            }
            .az-select__list__content__item--disabled .az-select__list__content__item__text,.az-select__list__content__item--suggestion .az-select__list__content__item__text{
                text-align: center;
            }
            
            
/* ### AZSelectMultiple */
.az-select-multiple{
    position: relative;
    border-radius: 1rem;
    border:1px solid var(--gray-text);
    overflow: hidden;    
    width: 100%;
}
.az-select-multiple--disabled{
    pointer-events: none;
    opacity: .4;
}
    .az-select-multiple__header{
        width: 100%;  
        display: flex;
        background-color:white;
        border: none;
        outline: none;
    }
        .az-select-multiple__header__title{            
            font-size: .9rem;
            padding: .2rem;
            flex: 0 0 auto;
            width: calc(100% - 32px);
            text-align: left;
            text-overflow: ellipsis;
            overflow-x: hidden;
        }
        .az-select-multiple__header__icon{                     
            color: inherit;            
            width: 10px;
            height: 10px;
            flex: 0 0 32px;
            text-align: center;
            align-self: center;
            display: table; 
        }
        .az-select-multiple__header__icon > *{                     
            color: inherit;                        
            font-size: 1rem;
            vertical-align: middle;
            text-align: center;        
        }
    .az-select-multiple__list{
        position: relative;
        width: 100%;
        max-height: 300px;
        overflow-y: auto;        
    } 
        .az-select-multiple__list__content{
            width: 100%;
            padding: .2rem;
        } 
            .az-select-multiple__list__content__item:hover{
                background-color: var(--gray-soft);
            }

            .az-select-multiple__list__content__item,.az-select-multiple__list__content__blank{
                width: 100%;
                text-align: left;
                font-size: .9rem;
                font-family: "Montserrat Bold";
                border: none;
                outline: none;
                background-color: transparent;               
                cursor: pointer;
                display: flex;
                padding: .2rem;
                padding-left: .8rem;
                border-bottom: 1px solid gray;
                color: var(--purple);
            }
            .az-select-multiple__list__content__blank{
                width: 100%;                
                margin: auto;
                height: 1px;
                background-color: var(--gray-titles);
                padding: 0;
                border-bottom: none;
            }
            .az-select-multiple__list__content__item--disabled{
                pointer-events: none;
                cursor:none;
                opacity: .6;                
                border-bottom: none;
                text-align: center!important;
                color: var(--gray-titles);
            }
            .az-select-multiple__list__content__item--suggestion{
                color: var(--purple) !important;
                text-align: center!important;
                text-decoration: underline;
                border-bottom: none;
            }

            .az-select-multiple__list__content__item--disabled .az-select-multiple__list__content__item__text{                                    
            }
            
            .az-select-multiple__list__content__item--disabled .az-select-multiple__list__content__item__text,.az-select-multiple__list__content__item--suggestion .az-select-multiple__list__content__item__text{
                text-align: center;
            }
            

                .az-select-multiple__list__content__item__text{
                    width: 100%;
                    font-size: .9rem;
                    font-family: "Montserrat Light";
                    border: none;
                    outline: none;
                    background-color: transparent;                    
                    text-align: left;
                    width: 100%;                                        
                }
                .az-select-multiple__list__content__item__icon{
                    color: inherit;
                    width: 10px;
                    height: 10px;
                    flex: 0 0 32px;
                    text-align: center;
                    align-self: center;
                    display: table;                    
                }

/* ### AZButton */
.btn-az{ 
    border: none;
    box-shadow: none;
    outline: none;
    border-radius: .5rem;        
    font-family: 'Montserrat SemiBold';   
}
.btn-az--sm{
    font-size: .7rem;
    padding: 0.4rem .6rem;
}
.btn-az--md{
    font-size: .9rem;
    padding: 0.6rem .8rem;
}
.btn-az--color{
    background-color: var(--purple);
    color: white;
}.btn-az--gray{
    background-color: var(--gray-titles);
    color: white;
}.btn-az--gray-soft{
    background-color: var(--gray-soft);
}
.btn-az--disabled{
    opacity: .6;
}
/* AZButtonIcon */
.btn-az-icon{ 
    border: none;
    box-shadow: none;
    outline: none;
    border-radius: .5rem;
    font-size: 1rem;
    padding: 0.6rem 1rem;
    font-family: 'Montserrat SemiBold';   
    cursor: pointer;
    display: flex;
    vertical-align: center;
    align-items: center;    
}.btn-az-icon--color{
    background-color: var(--purple);
    color: white;
}.btn-az-icon--danger{
    background-color: var(--red);
    color: white;
}.btn-az-icon--gray{
    background-color: var(--gray-titles);
    color: white;
}.btn-az-icon--gray-soft{
    background-color: var(--gray-text);
    color: white;
}
.btn-az-icon--disabled{
    opacity: .6;
    pointer-events: none;
}
.btn-az-icon--md{
    font-size: .9rem;
    padding: 0.6rem .8rem;
}
.btn-az-icon--sm{
    font-size: .9rem;
    padding: 0.4rem .6rem;
}
.btn-az-icon--xs {
    font-size: 0.6rem;
    padding: 0.2rem 0.4rem;    
}
.btn-az-icon__left-icon,.btn-az-icon__right-icon{
    display: table-cell;    
    height: 32px;
    width: 32px;
    text-align: center;    
}
.btn-az-icon__left-icon > *,.btn-az-icon__right-icon > *{
    vertical-align: middle;
    margin-bottom: 0;
    line-height: 32px;
}

.btn-az-icon--xs .btn-az-icon__left-icon,.btn-az-icon--xs .btn-az-icon__right-icon{
    height: 16px;
    width: 16px;
}
.btn-az-icon--xs .btn-az-icon__left-icon > *,.btn-az-icon--xs .btn-az-icon__right-icon > *{
    line-height: 16px;
}

.btn-az-icon__label{
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
    text-align: center;
    flex:1 0;
}
.btn-az-icon__label > *{
    word-wrap: break-word; 
}
/* AZButtonLink */
.btn-az-link{
    border: none;
    box-shadow: none;
    outline: none;
    font-size: .9rem;    
    padding-bottom: 0;
    font-family: 'Montserrat SemiBold';
    background-color: transparent;    
    text-align: left;
    font-weight: 600;
}.btn-az-link--color{
    color: var(--purple);
    border-color:var(--purple);
}.btn-az-link--gray{
    color: var(--gray-text);
    border-color:var(--gray-text);
}.btn-az-link--danger{
    color: var(--red);
    border-color:var(--red);
}.btn-az-link--underline{
    border-bottom: 1px solid;
}
.btn-az-link--sm{
    font-size: .9rem;
}
.btn-az-link--disabled{
    opacity: .6;
    pointer-events: none;
}
/* AZButtonIconLink */
.btn-az-icon-link{         
    font-family: 'Montserrat SemiBold';   
    cursor: pointer;
    display: flex;
    vertical-align: center;
    align-items: center;
    
    border: none;
    box-shadow: none;
    outline: none;
    font-size: .9rem;    
    padding-bottom: 0;
    background-color: transparent;        
    font-weight: 600;
}.btn-az-icon-link--color{    
    color: var(--purple);
}.btn-az-icon-link--gray{
    color: var(--gray-titles);
}.btn-az-icon-link--danger{
    color: var(--red);
}.btn-az-icon-link--white{
    color: white;
}.btn-az-icon-link--disabled{
    opacity: .6;
    pointer-events: none;
}
.btn-az-icon-link--fix-margin{
    margin-left: -.5rem;
}
.btn-az-icon-link__left-icon,.btn-az-icon-link__right-icon{
    display: table-cell;    
    height: 32px;
    width: 32px;
    text-align: center;    
}
.btn-az-icon-link__left-icon > *,.btn-az-icon-link__right-icon > *{
    vertical-align: middle;
    margin-bottom: 0;
    line-height: 32px;
}
.btn-az-icon-link__label{
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}
/* ### AZCheckbox */
.az-checkbox{
    border:none;
    box-shadow: none;
    outline: none;   
    border-radius: 1rem;
    font-size: 1rem;
    padding: .4rem;
    display: flex;
    align-items:baseline;
    align-content:flex-start;  
    cursor: pointer;  
}
    .az-checkbox__box{        
        width: 16px;
        height: 16px;
        border-radius: .3rem;
        border: 1px solid var(--gray-text);
        background-color: transparent;
    }.az-checkbox__box--checked{
        background-color: var(--purple);
    }
    .az-checkbox__text{
        width: 100%;
        padding-left:1rem;
    }
/* AZField */
.az-inputfield{    
    border:none;
    box-shadow: none;
    outline: none;
    width: 100%;    
}    
    .az-inputfield--disabled{
        pointer-events: none;
    }
    .az-inputfield__textfield {
        color: var(--gray-titles);
        border:1px solid var(--gray-text);
        width: 100%;
        font-size: .7rem;
        border-radius: 1rem;
        padding: .4rem;
        display: flex;
        background-color: white;
    }.az-inputfield--error .az-inputfield__textfield{
        color: red;
        border-color: red;
    }.az-inputfield--success .az-inputfield__textfield{
        color: var(--green);
        border-color: var(--green);
    }.az-inputfield__textfield .az-inputfield__textfield--focus{
        border-color: var(--purple);
    }.az-inputfield__textfield.az-inputfield__textfield--error.az-inputfield__textfield--focus{
        color: red;
        border-color: red;          
    }.az-inputfield--disabled .az-inputfield__textfield{
       opacity: .6;
       pointer-events: none; 
    }      
    .az-inputfield__textfield input{         
        border:none;
        box-shadow: none;
        outline: none;
        border: none;
        background-color: transparent;
        width: 100%;
        flex:1 1 auto;
        color: inherit;
    }
    .az-inputfield__textfield__icon{
        display: table;
        color: inherit;
        width: 16px;
        height: 16px;
        text-align: center;
        vertical-align: center;
        padding: 0.2rem;
        flex: 0 0;
        font-size: inherit;
    }
    .az-inputfield__textfield__icon > *{        
    }
    .az-inputfield__textfield__icon--loading{        
        font-size: inherit;        
    }
    .az-inputfield__error{
        width: 100%;
        position: relative;
        color: red;
        margin-top: .3rem;
    }

    .az-inputfield--left-icon .az-inputfield__textfield{
        padding-left: .8rem;
    }
    .az-inputfield--right-icon .az-inputfield__textfield{
        padding-right: .8rem;
    }
/* AZImgInput */
.az-img-input{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;    
}
    .az-img-input__img{
        border-radius:50%;
        width: 80px;
        height: 80px;
        overflow: hidden;
        cursor: pointer;
        background-color: transparent;
        display: flex;
    }
    .az-img-input__img--rect{
        border-radius:0;        
    }
        .az-img-input__img img{
            width: 100%;
        }
    .az-img-input__label{
        color:black;
        width: 100%;
        margin-top: .4rem;        
        text-align: center;
        text-align: center;
    }
    .az-img-input__error{
        color: red;
    }
/* AZPassword */
.az-password{
    border-radius: 1rem;
    border: 1px solid var(--gray-text);
    position: relative;
    display: flex;
    width: 100%;
    padding: .4rem;
    color: var(--gray-text);
    align-self: center;
}
    .az-password--error{
        color: red;
        border-color: red;
    }
    .az-password__textfield{
        width: calc(100% - 32px);    
        font-size: .7rem;    
    }
    .az-password__textfield input{
        border: none;       
        outline: none;
        width: 100%;
    }
    .az-password__icon{
        width: 20px;
        height: 20px;
        border: none;
        outline: none;
        background-color: transparent;
        color: var(--gray-text);
    }
        .az-password__icon img{
            text-align: center;
        }
    .az-password__error{
        color: red;
    }
/*AZTextArea*/
.az-textarea{
    border:none;
    box-shadow: none;
    outline: none;   
    border-radius: 1rem;
    font-size: 1rem;
    padding: .5rem;    
    border: 1px solid var(--gray-text);
    color: var(--gray-titles);
}
.az-textarea--error{    
    border-color: red;
}
    .az-textarea__field{        
        position: relative;    
        width: 100%;        
        font-size: .7rem;
        color: inherit;
        border: none;
    }
    .az-textarea__field textarea{
        border: none;
        outline: none;
        box-shadow: none;
        width: 100%;
        height: 100%;
        resize: none;
        color:inherit;
    }
    .az-textarea__error{
        color: red;
    }

/* AZAreaBubble */
.az-area-bubble{
    width: 100%;        
}
.az-area-bubble--bordered{
    border: 1px solid var(--gray-text);
    border-radius: 1rem;
}
.az-area-bubble__content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.az-area-bubble__content__item{
    background-color: var(--purple);
    color: white;
    display: flex;
    padding: .4rem;
    margin: 0.2rem;
    border-radius: 1rem;
    align-items: center;    
}
    .az-area-bubble__content__item__title{
        background-color: transparent;
        color: inherit;
        flex:0 0 auto;
        font-family: 'Montserrat SemiBold';
        font-size: .7rem;
        margin: .2rem;
    }
    .az-area-bubble__content__item__button{
        background-color: transparent;
        color: inherit;
        width: 16px;
        height: 16px;
        display: table;
        background-color: var(--red);
        border-radius: 50%;
        flex:0 0 16px;
    }
        .az-area-bubble__content__item__button > *{
            display: table-cell;
            font-size: .6rem;
            cursor: pointer;
            vertical-align: middle;
            text-align: center;
        }

/* AZVerticalCheck */
.az-vertical-check{
    color: var(--gray-text);
    text-align: center;
    position: relative;
    cursor: pointer;
}
.az-vertical-check--on{
    color: var(--purple);
}
.az-vertical-check--disabled{
   opacity: .4;
   pointer-events: none; 
}

    .az-vertical-check__check{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;        
    }
        .az-vertical-check--on .az-vertical-check__check__icon{
            color: white;  
            background-color: var(--purple); 
            display: table;     
        }
        .az-vertical-check__check__icon{
            background-color: var(--gray-text);
            width: 32px;
            height: 32px;
            border-radius: 50%;
            color: inherit;
            display: table-cell;
            vertical-align: middle;
        }
        .az-vertical-check__check__icon > *{
            color: inherit;
            vertical-align: bottom;
        }
    .az-vertical-check__label{
        color: inherit;
        font-size: .7rem;
    }

/* AZSwitch */

.az-switch{
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;    
    padding: .4rem;
    color: var(--gray-text);
}
    .az-switch--disabled{
        pointer-events: none;
        opacity: .6;
    }
    .az-switch--error{
        color: var(--red);        
    }
    .az-switch--purple{
        color: var(--purple);        
    }
    .az-switch__label{
         flex: 1 0;        
    }   
    .az-switch__icon{
        width: 32px;
        margin-left: .2rem;
        color: inherit;
    }        
    .az-switch__icon > *{
        font-size: 1rem;
        color: inherit;
    }        

/* AZButtonLevel */
.btn-az-level{
    border-radius: 1rem;
    background-color: var(--gray-soft);
    display:flex;
    width: 100%;
    padding: .8rem;
    align-items: center;
    cursor: pointer;
    min-height: 128px;
}
.btn-az-level--selected{
    background-color: var(--purple);
}
.btn-az-level__icon{
    width: 64px;
    height: 64px;
    color: var(--blue);
    flex: 0 0 64px;
    display: table;    
}
.btn-az-level__icon > *{
    color: inherit;
    font-size: 64px;    
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.btn-az-level__body{
    padding: .8rem;
    flex: 1 1 auto;
}
.btn-az-level__body__title{
    margin-left: -.4rem;
    font-size: 1rem;    
    color: var(--gray-titles);
    margin-bottom: 0;
}
.btn-az-level__body__description{
    font-size: .7rem;
    color: black;
    margin-bottom: 0;
}
.btn-az-level--selected .btn-az-level__icon,.btn-az-level--selected .btn-az-level__body__title,.btn-az-level--selected .btn-az-level__body__description{
    color: white;
}


/* Slider to all browsers */
    /*webkit  */
input[type=range] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: .4rem;
    cursor: pointer;   
    background-color: var(--purple);
    border-radius: 1rem;        
  }
  input[type=range]::-webkit-slider-thumb {    
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border:.4px solid black;
    -webkit-appearance: none;    
    margin-top: -.4rem;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--purple);
  }
  /*MOZ*/
  input[type="range"]::-moz-range-progress {
    background-color:var(--purple); 
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: .4rem;
    cursor: pointer;   
    background-color: var(--gray-text);
    border-radius: 1rem;   
  }
  input[type=range]::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border:.4px solid black;
    -webkit-appearance: none;    
    margin-top: -.4rem;
  }
  /* ms */
  input[type=range]::-ms-track {
    width: 100%;
    height: .4rem;
    cursor: pointer;   
    background-color: var(--gray-text);
    border-radius: 1rem;   
  }
  input[type=range]::-ms-fill-lower {     
    background-color: var(--purple);    
  }
  input[type=range]::-ms-fill-upper {
    background-color: var(--gray-text);
  }
  input[type=range]::-ms-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border:.4px solid black;
    -webkit-appearance: none;    
    margin-top: -.4rem;
  }
  input[type=range]:focus::-ms-fill-lower {
    background-color: var(--purple);
  }
  input[type=range]:focus::-ms-fill-upper {
    background-color: var(--gray-text);
  }