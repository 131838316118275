
.az-navbar-wrapper{
    box-shadow: 1px 1px 2px grey;
    padding: .2rem;
    width: 100%;
    align-items: center;
    background-color: white;
}
.az-navbar{
    display: flex;
    align-items: center;    
}

.az-navbar__logo{
    width: 128px;
    height: auto;
    padding-right: .4rem;
}
.az-navbar__logo img{
    width: 100%;
}
.az-navbar__items{
    display: flex;
    position: relative;
    align-items: center;
    padding:0;    
}
.az-navbar__items__item{
    display: table-cell;
    padding:.2rem;
    margin-left: .6rem;
}.az-navbar__items__item a{
    text-decoration: none;
    margin-bottom: 0;
    vertical-align: middle;
    text-align: center;
}
.az-navbar__items__item--selected{
    border-bottom: 1px solid var(--purple);
}
.az-navbar__type{
    display: flex;
    justify-content: center;
    align-items: center;
}
.az-navbar__type-item{
    padding: .4rem;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
}
.az-navbar__type-item--active{
    padding-bottom: .2rem;
    border-bottom: 1px solid var(--purple);
}

.az-navbar__type-item__notifications{
    position: absolute;
    right: 0;
    top: -1rem;
    width: 1.4rem;
    height: 1.4rem;
    background-color: var(--purple);
    text-align: center;
    color: white;
    border-radius: 0.7rem;
}

.az-navbar-menu-link{
    font-size: 1rem;
    font-family: 'Montserrat Light';
    color: black;
    padding-bottom:.6rem;
    padding-top:.6rem;
    border-bottom: .5px solid black;
}
.az-navbar-menu-link > a,.az-navbar-menu-link > a:focus,.az-navbar-menu-link > a:active,.az-navbar-menu-link > a:visited{
    outline: none;
    text-decoration: none;
    font-size: inherit;
    font-family: inherit;
}
.az-navbar-menu-link--last{
    border-bottom:none;
}
.az-navbar-menu-link > a > p{
    color: inherit;
    font-size: 1rem !important;
}