.az-profile-image{
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    padding: .4rem;
    flex: 0 0 auto;
    position: relative;
}.az-profile-image--md{
    width: 100px;
    height: 100px;
}
.az-profile-image--lg{
    width: 200px;
    height: 200px;
}
.az-profile-image--bordered .az-profile-image__image{
    border: 2px solid var(--purple);
}
.az-profile-image__image{
    border-radius: 50%;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
}.az-profile-image__image img{
    width: 100%;
}
.az-profile-image__label{
    width: 100%;
    text-align: center;
}
.az-profile-image__image__loading{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}
.az-profile-image__image__loading > *{
    margin: 0;
}

.az-profile-guru{
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: flex-end;
    justify-content: center;
}
    .az-profile-guru__content{
        position: relative;
        display: flex;
    }
.az-guru-badge{
    width: 12px;
    height: 12px;    
    text-align: center;
    color: white;
    font-size: 6px;
    display: inline-block;
    border-radius: 50%;    
    background-color: #3386FF;
    margin: 2px; 
}
.az-guru-badge--independent{
    color:salmon;
    background-color: #222;
}
.az-guru-badge--md{
    width: 16px;
    height: 16px;
    margin: 3px;
    font-size: 10px; 
}
.az-guru-badge--up{
    margin-top: -3px;     
}
.az-guru-badge__wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.az-guru-badge__wrapper > *{
    display: block;
    text-align: center;
    vertical-align: middle;
}
.review-tab-panel{
    display: flex;       
}
.review-tab-panel--disabled{
    pointer-events: none;
    opacity: .4; 
}
.review-tab-panel__item{
    padding: .8rem .4rem;
    border-bottom: 2px solid var(--gray-text);
    flex:1 0 50%;
    text-align: center;
    cursor: pointer;
}
.review-tab-panel__item > *{
    display: inline-block;
}
.review-tab-panel__item--selected{
    border-bottom: 2px solid var(--purple);
}
